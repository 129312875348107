<template class="main-login-section">
<div class="sign-in-container">
    <div class="logo">
        <img src="@/assets/images/logo.png" alt="logo" height="50vh" />
      </div>
    <div class="sign-in-content">
      <h3 class="mb-0 sign-in-title">Reset Password</h3>
      <p class="sign-in-description">Enter your email address and we'll send you an email with instructions to reset your password.</p>
      <!-- <router-link to="/auth/sign-in"> -->
        <ValidationObserver ref="form" v-slot="{ invalid }">
        <form action="/auth/sign-in" class="mt-4 sign-in-form">
            <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
            <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')" id="exampleInputEmail1" placeholder="Enter email" required>
                <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
                </div>
            </div>
            </ValidationProvider>
            <div class="sign-info d-inline-block w-100 sign-in-button">
                <button type="submit" class="sign-in-form-btn" :disabled="invalid">Reset Password</button>
            </div>
        </form>
      </ValidationObserver>
      <!-- </router-link> -->
      </div>
</div>
</template>
<script>
export default {
  name: 'RecoverPassword',
  date: () => ({
    user: {
      email: ''
    },
    textPassword: false
  }),
  methods: {
    onSubmit () {
      this.$refs.form.validate().then(valid => {
        if (valid) {
          this.$router.push('/auth/sign-in')
        }
      })
    }
  }
}
</script>
